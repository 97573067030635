import React from "react"
import styled from "styled-components"
import LocalMap from "../../images/map.jpg"
import Kelowna from "../../images/kelowna2.jpg"
import Layout from "../../components/layout-canada"
import SEO from "../../components/seo"
import LeafBorder from "../../images/hand-painted-leaf-border-cut.png"

const Picture = styled.div`
  background-image: url("${Kelowna}");
  background-size: cover;
  width: 100%;
  height: 400px;
  background-position: 10%;
  margin-bottom: 42px;
  @media (max-width: 768px) {
    background-position: 45%;
  }
`

const LeafBorderPicture = styled.div`
  background-image: url("${LeafBorder}");
  height: 30px;
  width: 260px;
  margin-bottom: 32px;
  background-size: 300px 30px;
`

const H1 = styled.h1`
  margin-bottom: 6px;
`

const P = styled.p`
  font-family: "Benton Sans";
  text-align: justify;
`

const H4 = styled.h4`
  font-family: "Benton Sans";
  margin-bottom: 24px;
`

const H5 = styled.h5`
  font-family: "Benton Sans";
  margin-bottom: 20px;
`

const Ul = styled.ul`
  font-family: "Benton Sans";
`

class TransportPage extends React.Component {
  render() {
    return (
      <Layout>
        <SEO title="Location & Transport | Sara & Tim" />
        <H1>Location & Transport</H1>
        <LeafBorderPicture />
        <Picture></Picture>
        <P>
          Kelowna is located in the heart of BC's wine country. Okanagan Lake is
          the main draw in the summer. This 135 km long jewel is a big draw for
          boaters (power and sail), swimmers and kite-boarders. Tourists also
          come to experience and taste the Okanagan's world class wines from
          various wineries throughout the valley.
        </P>
        <H4>Getting to Kelowna</H4>
        <H5>By air</H5>
        <P>
          Kelowna International Airport provides daily service to several cities
          including Vancouver, Calgary, Edmonton, Los Angeles, and Seattle.
        </P>
        <H5>By road</H5>
        <P>
          Kelowna is a 4 hour scenic drive from Vancouver (390 km) and a 7.5
          hour drive from Calgary (605 km). Both routes go through the Rocky
          Mountains.
        </P>
        <H4>Getting around in Kelowna</H4>
        <P>
          Kelowna has a{" "}
          <a href="https://www.bctransit.com/kelowna/home">bus network</a> that
          can be used to get around. However, if you don't have your own
          transortation, we strongly recommend either{" "}
          <a href="https://www.kayak.co.uk/Cheap-Kelowna-Car-Hire.5702.cars.ksp">
            hiring a vehicle
          </a>{" "}
          or <a href="https://currenttaxi.ca/">hiring taxis</a> as the coverage
          of the bus network is not comprehensive.
        </P>
        <P>
          Please add to the ride sharing Google doc if you have spare seats in
          your vehicle that you'd like to offer or are looking for a ride - save
          money, save the planet, make some new friends!
        </P>
      </Layout>
    )
  }
}

export default TransportPage
